// @import '../fonts/font.scss';
@import '../../apps/di-ld/src/variables.scss';

// Form
.form-control {
  margin-top: 24px;

  label {
    font-weight: 400;
  }

  input {
    min-height: 64px;
  }

  .dropdown-fluid-middle {
    width: 100%;
    height: 64px;

    & > div {
      height: 64px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
    }

    input {
      margin-top: -9px;
      margin-left: -9px;
    }
  }

  .p-inputgroup {
    input {
      border-right: 0;
    }

    .p-inputgroup-addon {
      &.text {
        background: none !important;
      }
    }
  }

  .input-number {
    .p-inputgroup-addon {
      &.text {
        background: none !important;
        height: 46pt;
        margin-top: 1px;
        position: absolute;
        right: 51%;
        border-radius: 8px;
        border: none;
      }
    }

    input {
      border-top: 0;
      margin-top: -6px;
      padding: 0;
      min-height: 58px;
      border-radius: 8px;
      border: none;
      margin-right: -7px;
      margin-left: 0.1px;
      text-align: left;
    }
  }

  .p-inputtext {
    font-family: $font-family;
    font-size: 18px;
    border-radius: 8px;
    font-weight: 400;
  }

  p-inputnumber.p-inputtext {
    border-radius: 8px;
  }

  .p-field-radiobutton {
    label {
      font-weight: normal;
    }
  }

  .text-help {
    font-size: 14px;
    color: #838383;
  }
}

// Form

//p-dropdown.ng-touched.ng-invalid>.p-dropdown {
.ng-submitted p-dropdown.ng-invalid > .p-dropdown,
p-dropdown.ng-touched.ng-invalid > .p-dropdown,
.ng-submitted p-dropdown.ng-touched.number-invalid > .p-dropdown,
.ng-submitted p-inputnumber.ng-touched.number-invalid {
  border-color: $red-color;
  .p-inputtext {
    color: $red-color;
  }
}

.ng-submitted p-inputnumber.ng-invalid,
p-inputnumber.ng-touched.ng-invalid {
  border-color: $red-color;
  color: $red-color;
}

.ng-submitted .p-inputtext.ng-invalid,
.p-inputtext.ng-touched.ng-invalid {
  border-color: $red-color;
}

.ng-submitted {
  p-inputnumber {
    &.ng-invalid {
      .p-inputnumber-input {
        border-color: $red-color;
      }
    }
  }
}

p-inputnumber {
  &.ng-touched.ng-invalid {
    .p-inputnumber-input {
      border-color: $red-color;
    }
  }
}
