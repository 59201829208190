@import 'node_modules/primeicons/primeicons';
@import 'node_modules/primeng/resources/themes/saga-blue/theme';
@import 'node_modules/primeng/resources/primeng.min';
@import 'node_modules/primeflex/primeflex';
@import '../../../assets/fonts/font';
@import '../../../assets/style/main';
@import 'variables';

:root {
  --primary-pt-blue: #4457e3;
  --next-disabled-text: #8a9fab;
  --secondary-color: #565656;
  --border-color: #e0e0e0;
  --fs-header: 20px;
  --fs-normal: 16px;
  --fs-small: 14px;
  --fw-normal-weight: 400;
  --fw-bold-weight: 600;
}

body {
  margin: 0px;
  background-color: #ffffff;
  min-width: fit-content;
  font-family: $font-family;
  font-size: 16px;
  color: #212121;
  overflow-x: hidden;
  font-weight: var(--fw-normal-weight);
}

.p-component {
  font-family: $font-family;
  font-size: 16px;
  margin: 0px;
}

.p-card {
  border-radius: 16px !important;
  color: $black-color;
}

.p-checkbox {
  .p-checkbox-box {
    border: 1px solid #ced4da;
  }
  .p-checkbox-box.p-highlight {
    background: $primary-color !important;
    border-color: $primary-color !important;
  }
}
.p-checkbox-label {
  margin-left: 16px;
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #8a9fab !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #8a9fab !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #8a9fab !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #8a9fab !important;
}

.p-inputnumber-input {
  text-align: right;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
}

.p-dropdown {
  border: 1px solid #e0e0e0;
  border-radius: 8px !important;

  .p-dropdown-trigger {
    color: $primary-color;
    margin-right: 8px;
    .p-dropdown-trigger-icon {
      color: #838383;
    }
  }

  .p-dropdown-label.p-placeholder {
    color: #8a9fab !important;
    opacity: 0;
  }
}

.p-radiobutton {
  position: relative;
  .p-radiobutton-box {
    border: 1px solid var(--border-color) !important;
    position: relative;
    &.p-highlight {
      border-color: $primary-color !important;
      background: $primary-color !important;
      &.p-focus {
        box-shadow: none;
      }
      &:hover {
        border-color: $primary-color !important;
        background: $primary-color !important;
      }
      &::before {
        font-family: 'primeicons';
        content: '';
        position: absolute;
        color: white;
        z-index: 1;
        padding: 4px;
        border-radius: 50px;
        left: -1px;
        top: -1px;
        font-size: 12px;
      }
    }
    .p-radiobutton-icon {
      background-color: $primary-color !important;
    }
  }
}

::ng-deep .p-card {
  border-radius: 16pt;
}

.head {
  font-size: 16px;
  font-weight: var(--fw-bold-weight);
}

// Modal
.p-dialog {
  border-radius: 16pt;

  .p-dialog-title {
    margin-top: 16px;
  }

  .p-dialog-content {
    white-space: pre-line;
    border-radius: 12px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.08);
    font-size: 14px;
    padding: 16px;
  }
}
// Modal

.p-inputtext {
  border: 1px solid #e0e0e0;
  font-family: $font-family;
  padding-left: 16px !important;
  color: #212121;

  &[type='number'] {
    padding-left: 10px !important;
  }
  padding-top: 30px !important;
}

.payment-details {
  .p-inputtext {
    padding: 12px 16px !important;
    height: 73px;
  }
}

.p-slider .p-slider-handle {
  box-shadow: 0 2px 4px 0 rgba(188, 204, 214, 0.8);
  border: none;
  height: 1.5rem;
  width: 1.5rem;
  background: var(--primary-pt-blue);
}
.p-slider:not(.p-disabled) .p-slider-handle:hover {
  background: var(--primary-pt-blue);
  border-color: var(--primary-pt-blue);
}
.p-slider-horizontal .p-slider-handle {
  top: -2px !important;
}
.p-slider.p-slider-horizontal .p-slider-handle {
  margin-top: -9px !important;
  margin-left: -2px !important;
  width: 32px;
  height: 32px;
  z-index: 2;
}
// Common use
.w-100 {
  width: 100%;
}
.fw-bold {
  font-family: $font-family;
  font-weight: var(--fw-bold-weight) !important;
  font-style: normal;
}
.fw-normal {
  font-family: $font-family;
  font-weight: var(--fw-normal-weight) !important;
  font-style: normal;
}

.bg-white {
  background-color: $white-color;
}

.event-unselect {
  pointer-events: none;
  user-select: none;
}

.border-style {
  border: 1px solid var(--border-color);
}

.p-steps .p-steps-item.p-highlight .p-steps-number {
  background: inherit;
}

.pi {
  font-size: 18px;
}

.p-fluid .p-dropdown .p-dropdown-label {
  color: #000000;
}

.p-inputtext:enabled:hover {
  border-color: #e0e0e0;
  box-shadow: none;
  background-color: #ffffff;
}

.p-dropdown:not(.p-disabled).p-focus {
  border-color: #e0e0e0;
  box-shadow: none;
  background-color: #ffffff;
}

.ng-submitted .p-inputtext.ng-invalid,
.p-inputtext.ng-touched.ng-invalid {
  border-color: $red-color;
}

.p-disabled,
.p-component:disabled {
  opacity: 1;
}
.link-color {
  color: $yellow-link-color;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.p-d-flex-col {
  flex-direction: column;
}

.p-text-center {
  text-align: center;
}

.p-float-label label {
  color: #838383;
  left: 15px;
  font-size: 18px;
}

.p-float-label textarea ~ label {
  top: 1rem;
}

.p-float-label input:focus ~ label,
.p-float-label input.p-filled ~ label,
.p-float-label textarea:focus ~ label,
.p-float-label textarea.p-filled ~ label,
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
  top: 1.3rem;
  font-size: 14px !important;
  left: 17px;
  color: var(--primary-pt-blue);
}

.p-float-label .input:-webkit-autofill ~ label {
  top: -20px;
  font-size: 14px;
}

.p-slider.p-slider-horizontal {
  height: 0.5rem;
  margin-bottom: 25px;
  margin-right: 27px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.info-section-line {
  width: 100%;
  height: 1px;
  opacity: 1;
  background-color: #f2f2f2;
  margin: 8px 0px 8px 0px;
}

.amount-installment {
  .p-button-label {
    font-size: 18px;
    font-weight: var(--fw-bold-weight);
    color: var(--secondary-color);
  }
}

.installment-option {
  margin-left: -8px;
  margin-right: -8px;
  .p-button {
    border-radius: 8px;
    &.p-button-outlined {
      border: 1px solid var(--border-color);
      color: var(--secondary-color);
      font-size: 18px;
      background: #fff;
      &:enabled {
        &:hover {
          color: var(--primary-pt-blue);
          background: #fff;
        }
      }
    }
    &.active {
      border: 1px solid var(--primary-pt-blue);
      color: var(--primary-pt-blue);
      font-weight: 600;
      box-shadow: 0px 0px 0px 2px rgba(68, 87, 227, 0.2);
      .p-button-label {
        color: var(--primary-pt-blue);
      }
    }
  }
}

.primary-color {
  color: var(--primary-pt-blue);
}

.radio-button-wraper {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  height: 48px;
  position: relative;
  &.highlight {
    border-color: var(--primary-pt-blue);
    box-shadow: 0px 0px 0px 2px rgba(68, 87, 227, 0.2);
  }
  label {
    position: relative;
    left: 26px;
    top: 11px;
  }
  .p-radiobutton {
    left: 10px;
    top: 10px;
  }
}

.list-item {
  ul {
    margin: 0;
    padding-left: 30px;
  }
}

#require-help,
#error-help,
.error-msg-help {
  font-size: 14px;
  position: relative;
  top: 0px;
  left: 15px;
}
.error-msg-help {
  color: #838383;
}
.container {
  padding-top: calc(48px + 60px) !important;
}
.no-scroll {
  overflow: hidden;
}

// TOAST MESSAGE
:host ::ng-deep .custom-toast .ui-toast-message {
  width: 90%;
}

.p-toast .p-toast-message.p-toast-message-success {
  background: linear-gradient(94.84deg, #7ebb2a 0%, #62a507 100%);
  border: unset;
  border-width: unset;
  color: #ffffff;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 500;
}

.p-toast .p-toast-message .p-toast-icon-close {
  display: none;
}

.p-toast {
  opacity: 1;
}

.p-error {
  color: #db0000 !important;
}

.no-title .header {
  display: none;
}

.no-arrow .arraw-back {
  display: none;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
}

@keyframes p-component-overlay-enter-animation {
  from {
    background-color: transparent;
  }
  to {
    background-color: rgba(33, 33, 33, 0.8);
  }
}

.p-dialog .p-dialog-content {
  color: #212121;
}
