@font-face {
  font-family: 'Kanit';
  src: url('/assets/fonts/Paotang/Kanit-Regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/assets/fonts/Paotang/Kanit-Regular.woff')
      format('woff'),
    /* Modern Browsers */ url('/assets/fonts/Paotang/Kanit-Regular.ttf')
      format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Kanit';
  src: url('/assets/fonts/Paotang/Kanit-SemiBold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('/assets/fonts/Paotang/Kanit-SemiBold.woff')
      format('woff'),
    /* Modern Browsers */ url('/assets/fonts/Paotang/Kanit-SemiBold.ttf')
      format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
